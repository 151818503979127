<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <span class="brand-logo">
            <b-img
              :src="appLogoImage"
              alt="logo"
              style="margin-bottom: -40px"
            />
          </span>
        </b-link>

        <!-- form -->
        <validation-observer
          v-if="!isLoading"
          ref="registerForm"
          tag="form"
          #default="{ invalid }"
        >
          <b-form class="auth-login-form mt-2">
            <b-form-group label="Province">
              <validation-provider
                #default="{ errors }"
                name="Province"
                :rules="requiredValidation"
              >
                <model-select 
                  @input="chain_filter('get_filter_regencies')" 
                  :options="dropdown_province" 
                  v-model="registerForm.province" 
                  placeholder="-- Please select --"
                ></model-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Regencies">
              <validation-provider
                #default="{ errors }"
                name="Regencies"
                :rules="requiredValidation"
              >
                <model-select 
                  @input="chain_filter('get_filter_district')" 
                  :options="dropdown_regencies" 
                  v-model="registerForm.regencies" 
                  placeholder="-- Please select --"
                ></model-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="District">
              <validation-provider
                #default="{ errors }"
                name="District"
                :rules="requiredValidation"
              >
                <model-select 
                  @input="chain_filter('get_filter_villages')" 
                  :options="dropdown_district" 
                  v-model="registerForm.district" 
                  placeholder="-- Please select --"
                ></model-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Villages">
              <validation-provider
                #default="{ errors }"
                name="Villages"
                :rules="requiredValidation"
              >
                <model-select 
                  @input="chain_filter('get_customer_site')" 
                  :options="dropdown_villages" 
                  v-model="registerForm.villages" 
                  placeholder="-- Please select --"
                ></model-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Customer Site">
              <validation-provider
                #default="{ errors }"
                name="Customer Site"
                :rules="requiredValidation"
              >
                <model-select
                  @input="get_customer_group"
                  :options="dropdown_customer_site"
                  v-model="registerForm.code_site"
                  placeholder="-- Please select --"
                  :state="errors.length > 0 ? false : null"
                ></model-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Customer Group">
              <validation-provider
                #default="{ errors }"
                name="Customer Group"
                :rules="requiredValidation"
              >
                <model-select
                  @input="get_customer_type"
                  :options="dropdown_customer_group_type"
                  v-model="registerForm.code_group"
                  placeholder="-- Please select --"
                  :state="errors.length > 0 ? false : null"
                ></model-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Customer Type">
              <validation-provider
                #default="{ errors }"
                name="Customer Type"
                :rules="requiredValidation"
              >
                <model-select
                  @input="get_location"
                  :options="dropdown_customer_type"
                  v-model="registerForm.code_type"
                  placeholder="-- Please select --"
                  :state="errors.length > 0 ? false : null"
                ></model-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Location">
              <validation-provider
                #default="{ errors }"
                name="Location"
                :rules="requiredValidation"
              >
                <model-select
                  @input="get_location_area"
                  :options="dropdown_location"
                  v-model="registerForm.location"
                  placeholder="-- Please select --"
                  :state="errors.length > 0 ? false : null"
                ></model-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Location Area">
              <validation-provider
                #default="{ errors }"
                name="Location Area"
                :rules="requiredValidation"
              >
                <model-select
                  :options="dropdown_location_area"
                  v-model="registerForm.location_area"
                  placeholder="-- Please select --"
                  :state="errors.length > 0 ? false : null"
                ></model-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Handphone" label-for="Handphone">
                <validation-provider #default="{ errors }" name="Handphone" rules="required|min:9">
                  <cleave id="handphone" v-model="registerForm.phone_number" :class=" errors.length === 0 ? 'form-control' : 'form-control is-invalid'" :raw="false" :options="options.phone_number" placeholder="Handphone"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            <!-- Email -->
            <b-form-group label="Email" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="registerForm.email"
                  :state="errors.length > 0 ? false : null"
                  name="register-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- captha -->
            <b-form-group>
              <vue-recaptcha
                ref="invisibleRecaptcha"
                @verify="onVerify"
                @expired="onExpired"
                :sitekey="siteKey"
                :loadRecaptchaScript="true"
              ></vue-recaptcha>
            </b-form-group>
            <b-form-group>
              <b-link @click="newAddress">Alamat Tidak Ditemukan ?</b-link>
            </b-form-group>
            <!-- submit button -->
            <b-button
              variant="primary"
              type="button"
              block
              @click="submitRegister"
              :disabled="invalid"
              >Submit</b-button
            >
          </b-form>
        </validation-observer>
        <loading v-if="isLoading"></loading>
      </b-card>
      <!-- modal -->
      <b-modal
        ref="modalForm"
        id="addModals"
        header-border-variant="no-border text-center"
        footer-border-variant="no-border"
        footer-bg-variant="light"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        size="lg"
        hide-footer
      >
        <div slot="modal-header" class="text-center">
          <h3 class="text-center">Questioner</h3>
        </div>

        <validation-observer
          v-if="!isLoading"
          ref="questionerModal"
          tag="form"
          @submit.prevent="submitAddModal"
        >
          <b-form>
            <b-col
              v-for="(options_list, question, index) in questioner"
              :key="question"
              :label="question"
            >
              <b-row>
                {{ ++index }}.
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    :name="options_list.name"
                    :rules="answer[1] === '2' ? {} : requiredValidation"
                  >
                    <b-form-group :key="question" :label="question">
                      <div v-if="options_list.type == 1">
                        <b-form-radio
                          @input="rerfeshValueEmit"
                          v-model="answer[options_list.id_question]"
                          v-for="(
                            options_detail, options_index
                          ) in options_list.options"
                          :key="options_index"
                          :name="options_list.id_question"
                          :value="options_detail.value"
                          >{{ options_detail.text }}</b-form-radio
                        >
                      </div>
                      <div v-if="options_list.type == 2">
                        <b-form-checkbox-group
                          @input="rerfeshValueEmit"
                          v-model="answer[options_list.id_question]"
                          :options="options_list.options"
                          stacked
                        >
                        </b-form-checkbox-group>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
            <b-form-group>
              <b-button
                class="float-right"
                type="button"
                variant="primary"
                @click="submitAddModal"
                ><i class="fa fa-save"></i> Lanjutkan</b-button
              >
            </b-form-group>
          </b-form>
        </validation-observer>
        <loading v-if="isLoading"></loading>
      </b-modal>
      <!-- /modal -->
    </div>
  </div>
</template>
<script
  src="https://unpkg.com/vue-recaptcha@latest/dist/vue-recaptcha.min.js"
  defer>
</script>
<script
  src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
  async
  defer>
</script>
  
  <script>
import axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import interfaces from "@/templates/_interfaces.js";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Loading from "@core/loading/Loading.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import VueRecaptcha from "vue-recaptcha";
import Cleave from "vue-cleave-component";

export default {
  components: {
    // BSV
    Loading,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    VueRecaptcha,
    Cleave,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
    };
  },
  props: {
    requiredValidation: {
      type: String,
      default: "required",
    },
  },
  // mixins: [togglePasswordVisibility],
  data() {
    return {
      siteKey: "6LcBoC4jAAAAAOiSKf0hR3G9RVowFtQw0EZOXUhG",
      isLoading: false,
      registerForm: interfaces.register,
      dropdown_province: [],
      dropdown_regencies: [],
      dropdown_district: [],
      dropdown_villages: [],
      dropdown_customer_area: [],
      dropdown_customer_site: [],
      dropdown_customer_group_type: [],
      dropdown_customer_type: [],
      dropdown_location: [],
      dropdown_location_area: [],
      questioner: [],
      answer: [],
      show_location: null,
      options:{
        phone_number: {
          blocks: ["14"],
          prefix: '+62',
          numericOnly: true,
        },
      }
    };
  },
  methods: {
    rerfeshValueEmit: function () {
      this.$emit("refreshQuestioner", this.answer);
    },
    init: function () {
      // this.get_customer_area();
      this.$refs.modalForm.show();
      this.show_location = 1;
      this.getProvince();
      this.getData();
    },
    getData: function () {
      this.isLoading = true;
      let response = axios.post("self_registration/questioner/listing", {
        show_location: this.show_location,
      });
      return response
        .then((response) => {
          this.questioner = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          return [];
        });
      },
    getProvince: function () {
      axios.get("self_registration/dropdown/province").then((response) => {
        this.dropdown_province = response.data.data;
      });
    },
    chain_filter: function(val) {
      switch (val) {
        case 'get_filter_regencies':
            this.dropdown_regencies = [];
            this.registerForm.regencies = null;
            this.dropdown_district = [];
            this.registerForm.district = null;
            this.dropdown_villages = [];
            this.registerForm.villages = null;
            this.dropdown_customer_area = [];
            this.registerForm.code_area = null;
            this.dropdown_location = [];
            this.registerForm.location = null;
            this.dropdown_customer_type = [];
            this.registerForm.code_type = null;
            this.dropdown_customer_group_type = [];
            this.registerForm.code_group = null;
            this.dropdown_location_area = [];
            this.registerForm.location_area = null;
            axios.get("self_registration/dropdown/regency/"+this.registerForm.province).then((response) => {
              this.dropdown_regencies = response.data.data;
            }).catch((error) => {

            }).finally(() => {
              
            });
          break;
        
        case 'get_filter_district':
            this.dropdown_district = [];
            this.registerForm.district = null;
            this.dropdown_villages = [];
            this.registerForm.villages = null;
            this.dropdown_customer_area = [];
            this.registerForm.code_area = null;
            this.dropdown_location = [];
            this.registerForm.location = null;
            this.dropdown_customer_type = [];
            this.registerForm.code_type = null;
            this.dropdown_customer_group_type = [];
            this.registerForm.code_group = null;
            this.dropdown_location_area = [];
            this.registerForm.location_area = null;
            axios.get("self_registration/dropdown/district/"+this.registerForm.regencies).then((response) => {
              this.dropdown_district = response.data.data;
            }).catch((error) => {

            }).finally(() => {

            });
          break;
        
        case 'get_filter_villages':
            this.dropdown_villages = [];
            this.registerForm.villages = null;
            this.dropdown_customer_area = [];
            this.registerForm.code_area = null;
            this.dropdown_location = [];
            this.registerForm.location = null;
            this.dropdown_customer_type = [];
            this.registerForm.code_type = null;
            this.dropdown_customer_group_type = [];
            this.registerForm.code_group = null;
            this.dropdown_location_area = [];
            this.registerForm.location_area = null;
            axios.get("self_registration/dropdown/village/"+this.registerForm.district).then((response) => {
              this.dropdown_villages = response.data.data;
            }).catch((error) => {

            }).finally(() => {

            });
          break;

        case 'get_customer_site':
            this.get_customer_site(this.registerForm);
          break;

        default:
          break;
      }
    },
    get_filter_customer_area_province: function (e) {
      this.dropdown_location = [];
      this.registerForm.location = null;
      this.dropdown_customer_type = [];
      this.registerForm.code_type = null;
      this.dropdown_customer_group_type = [];
      this.registerForm.code_group = null;
      this.dropdown_location_area = [];
      this.registerForm.location_area = null;
      axios.get("self_registration/dropdown/customer_area_province/"+e.province+"/"+e.regencies+"/"+e.district+"/"+e.villages).then((response) => {
        this.dropdown_customer_area = response.data.data;
      }).catch((error) => {

      }).finally(() => {

      });
    },
    submitAddModal: function () {
      this.$refs.questionerModal.validate().then((success) => {
        if (success) {
          this.$refs.modalForm.hide();
        }
      });
    },
    get_customer_area: function () {
      axios
        .get("self_registration/dropdown/customer-area/")
        .then((response) => {
          this.dropdown_customer_area = response.data.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    get_customer_site: function (e) {
      console.log(this.registerForm);
      this.dropdown_location = [];
      this.registerForm.location = null;
      this.dropdown_customer_type = [];
      this.registerForm.code_type = null;
      this.dropdown_customer_group_type = [];
      this.registerForm.code_group = null;
      this.dropdown_location_area = [];
      this.registerForm.location_area = null;
      // axios.get("self_registration/dropdown/customer-site").then((response) => {
      //     this.dropdown_customer_site = response.data.data;
      // }).finally(() => {
      //   this.isLoading = false;
      // });

      axios.post("self_registration/dropdown/customer-site",{province:this.registerForm.province,regencies:this.registerForm.regencies,district:this.registerForm.district,villages:this.registerForm.villages}).then((response) => {
          this.dropdown_customer_site = response.data.data;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    get_customer_group: function () {
      this.dropdown_location = [];
      this.registerForm.location = null;
      this.dropdown_customer_type = [];
      this.registerForm.code_type = null;
      this.dropdown_customer_group_type = [];
      this.registerForm.code_group = null;
      this.dropdown_location_area = [];
      this.registerForm.location_area = null;

      axios.get("self_registration/dropdown/customer-group-type").then((response) => {
        this.dropdown_customer_group_type = response.data.data;
      });
    },
    get_customer_type: function () {
      this.dropdown_location = [];
      this.registerForm.location = null;
      this.dropdown_customer_type = [];
      this.registerForm.code_type = null;
      this.dropdown_location_area = [];
      this.registerForm.location_area = null;
      axios
        .get("self_registration/dropdown/customer-type/" + this.registerForm.code_group)
        .then((response) => {
          this.dropdown_customer_type = response.data.data;
        });
    },
    get_location: function () {
      this.dropdown_location = [];
      this.registerForm.location = null;
      this.dropdown_location_area = [];
      this.registerForm.location_area = null;
      axios
        .get(
          "self_registration/dropdown/location/" +
            this.registerForm.code_site +
            "/" +
            this.registerForm.code_group +
            "/" +
            this.registerForm.code_type
        )
        .then((response) => {
          this.dropdown_location = response.data.location;
        });
    },
    get_location_area: function () {
      this.dropdown_location_area = [];
      this.registerForm.location_area = null;
      axios
        .get("self_registration/dropdown/location-area/" + this.registerForm.location)
        .then((response) => {
          this.dropdown_location_area = response.data.location_area;
        });
    },
    newAddress: function () {
      this.$router.push({ path: "/new-register-address" });
    },
    handleSubmit: function () {
      this.isLoading = false;
      this.$router.push({ path: "/response-email" });
    },
    onSubmit: function () {
      if (this.registerForm.captcha) {
        this.$refs.registerForm.validate().then((success) => {
          if (success) {
            useJwt
              .register({
                code_area: this.code_area,
                code_group: this.code_group,
                code_type: this.code_type,
                location: this.location,
                location_area: this.location_area,
                phone_number: this.phone_number,
              })
              .then((response) => {
                useJwt.setToken(response.data.accessToken);
                useJwt.setRefreshToken(response.data.refreshToken);
                localStorage.setItem(
                  "userData",
                  JSON.stringify(response.data.userData)
                );
                this.$ability.update(response.data.userData.ability);
                this.$router.push("/");
              })
              .catch((error) => {
                this.$refs.registerForm.setErrors(error.response.data.error);
              });
          }
        });
      } else {
        alert("Please verify you are not robot");
      }
    },
    resetForm: function() {
      this.registerForm.captcha = null;
      this.registerForm.code_area = null;
      this.registerForm.code_group = null;
      this.registerForm.code_type = null;
      this.registerForm.location = null;
      this.registerForm.location_area = null;
      this.registerForm.email = null;
      this.registerForm.phone_number = '+62';
    },
    onVerify: function (response) {
      this.registerForm.captcha = response;
    },
    onExpired: function () {
      alert("Your verification expired, please fill all information again");
      this.resetForm();
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    submitRegister: function () {
      if (this.registerForm.captcha == null) {
        alert("Invalid Captcha!");
        this.resetForm();
      } else {
        this.$refs.registerForm.validate().then((success) => {
          if (success) {
            if (confirm("Are you sure want to Add this data ?")) {
              this.isLoading = true;
              this.registerForm.questioner = this.answer;
              this.registerForm.show_location = 1;
              axios
                .post("self_registration/registration/add", this.registerForm)
                .then((response) => {
                  if (response.data.status == 2000) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Form Add successful",
                        icon: "EditIcon",
                        variant: "success",
                        text: response.data.message,
                      },
                    });
                    this.handleSubmit();
                  } else {
                    this.resetForm();
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Form Add unsuccessful",
                        icon: "EditIcon",
                        variant: "warning",
                        text: response.data.message,
                      },
                    });
                  }
                })
                .catch((error) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Form Add Failed",
                      icon: "EditIcon",
                      variant: "danger",
                      text: error.message,
                    },
                  });
                })
                .finally(() => {
                  this.isLoading = false;
                  // this.resetForm();
                  // this.init();
                });
            }
          }
        });
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.card-body {
  padding: 2.5rem;
}
</style>
  